body {
  margin: 0;
  padding: 0;
    background-color: #fafafa;
}

.underline {
    text-decoration: underline;
}

.pointer{
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}